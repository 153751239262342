// import AsyncStorage from "@react-native-async-storage/async-storage";
import { RequestHandler } from "./Request";
import Cookies from "js-cookie";

const request = new RequestHandler();

export function GetBlogCategories(): Promise<any> {
  return new Promise((resolve, reject) => {
    request.get("blog/category/get", " ", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}

// @ts-ignore
export function GetAllBlogs(catId, isPremium, page): Promise<any> {
  return new Promise((resolve, reject) => {
    const url = catId
      ? `blog/all/get?limit=9&page=${page}&categoryId=${catId}&isPremium=${isPremium}`
      : `blog/all/get?limit=9&page=${page}&isPremium=${isPremium}`;

    request.get(url, " ", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}
export function GetAllUserBlogs(
  catId: number,
  isPremium: Boolean,
  page: number
): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      const url = catId
        ? `blog/all/get?limit=9&page=${page}&categoryId=${catId}&isPremium=${isPremium}`
        : `blog/all/get?limit=9&page=${page}&isPremium=${isPremium}`;

      request.get(url, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    }
  });
}

export function GetBlogBySlug(slug: any): Promise<any> {
  return new Promise((resolve, reject) => {
    request.get(`blog/get/slug/${slug}`, " ", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}

export function GetUSerBlogBySlug(slug: any): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.get(`blog/get/slug/${slug}`, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    }
  });
}

export function BookMark(id: any): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.post(`blog/bookmark/${id}`, {}, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}

export function RemoveBookMark(id: any): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.put(`blog/bookmark/${id}`, {}, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}

export function GetBookMarks(catID: number): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");

    if (token) {
      token = token.replace(/^"|"$/g, "");
      const url = catID ? `blog/bookmark?categoryId=${catID}` : `blog/bookmark`;
      request.get(url, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}

export function GetRecommendation(premium: any, catID: number): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    const url = `blog/recommended?isPremium=${premium}&limit=3&page=1&categoryid=${catID}`;
    request.get(url, "", [
      (data: any) => {
        resolve(data);
      },
    ]);
    // if (token) {
    //   token = token.replace(/^"|"$/g, "");
    // } else {
    // }
  });
}

export function SearchBlog(premium: any, searchValue: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const url = `blog/search?searchValue=${searchValue}&isPremiun=${premium}&limit=9&page=1`;
    request.get(url, "", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}
export function SearchBlogOnCategoties(
  premium: any,
  searchValue: any,
  catId: any
): Promise<any> {
  return new Promise((resolve, reject) => {
    const url = catId
      ? `blog/search?searchValue=${searchValue}&isPremiun=${premium}&categoryId=${catId}&limit=9`
      : `blog/search?searchValue=${searchValue}&isPremiun=${premium}&limit=9`;
    request.get(url, "", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}
