import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/style.css";
import Logo from "../assests/images/Logo.png";
import dropDown from "../assests/images/dropdown.svg";
import dropUp from "../assests/images/dropUp.svg";
import hamburg from "../assests/images/hamburg.svg";
import close from "../assests/images/close.svg";
import Cookies from "js-cookie";
import solidDropUp from "../assests/images/solid-dropUp.svg";
import solidDropdown from "../assests/images/solid-dropdown.svg";
import { GetUserDetails } from "../RequestHandlers/User";
import Logout, { handleLogout } from "./Logout";

// import 'bootstrap/dist/css/bootstrap.min.css';

interface NavProps {
  onDefi?: any;
  onTxApp?: any;
  onTkProjects?: any;
  onFactoryProject?: any;
  onReserveCard?: any;
}

const Navbar = ({
  onDefi,
  onTxApp,
  onTkProjects,
  onFactoryProject,
  onReserveCard,
}: NavProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLogDropdownOpen, setIsLogDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleLogDropdownToggle = () => {
    setIsLogDropdownOpen(!isLogDropdownOpen);
  };
  const navigate = useNavigate();
  const token = Cookies.get("webCook");

  const UserDetails = async () => {
    try {
      const userDetails = await GetUserDetails();
      console.log(userDetails);
      setUserInfo(userDetails);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    UserDetails();
  }, []);

  return (
    <nav className={`navbar ${isMenuOpen ? "expanded" : ""}`}>
      <div
        className="container-fluid"
        style={{
          height: isMenuOpen ? 64 : "",
          paddingTop: isMenuOpen ? 16 : "",
        }}
      >
        <Link
          className="navbar-brand"
          to="/"
          style={{ marginLeft: isMenuOpen ? 16 : "" }}
        >
          <img src={Logo} className="logo" alt="Logo" />
        </Link>
        <button
          className="menu-toggle"
          onClick={toggleMenu}
          style={{ marginRight: isMenuOpen ? 16 : "" }}
        >
          <img src={isMenuOpen ? close : hamburg} alt="" />
        </button>
      </div>
      {isMenuOpen && (
        <div className="menu-Link">
          <ul style={{ marginLeft: 16 }}>
            <li className="nav-item">
              <Link className="nav-link menu-link redHat-semiBold" to="/about">
                About us
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                className="nav-link menu-link redHat-semiBold"
                to={"https://onboard.trendx.app"}
              >
                Onboard on Trendx
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                className="nav-link menu-link redHat-semiBold"
                to={token ? "/blogDashboard" : "/blog"}
              >
                Blog
              </Link>
            </li>
            <li className="nav-item dropdown" style={{marginBottom: 0}}>
              <Link
                className="nav-link menu-link redHat-semiBold"
                to=""
                onClick={handleDropdownToggle}
              >
                Company portfolio
                <img
                  src={isDropdownOpen ? dropUp : dropDown}
                  alt=""
                  style={{ marginLeft: 12 }}
                />
              </Link>
              {isDropdownOpen && (
                <ul
                  className="dropdown-menu"
                  style={{ background: "none", border: "none", paddingTop: 24, marginTop: 0}}
                >
                  <li className="dropdown-item">
                    <div
                      className="dropdown-link dm-link"
                      onClick={() => {
                        setIsMenuOpen(false);
                        onDefi();
                        setIsDropdownOpen(false);
                      }}
                    >
                      De-Fi research
                    </div>
                  </li>
                  <li className="dropdown-item">
                    <div
                      className="dropdown-link dm-link"
                      onClick={() => {
                        setIsMenuOpen(false);
                        onDefi();
                        setIsDropdownOpen(false);
                      }}
                    >
                      Advisory service
                    </div>
                  </li>
                  <li className="dropdown-item">
                    <div
                      className="dropdown-link dm-link"
                      onClick={() => {
                        setIsMenuOpen(false);
                        onTxApp();
                        setIsDropdownOpen(false);
                      }}
                    >
                      Trendx app
                    </div>
                  </li>
                  <li className="dropdown-item">
                    <div
                      className="dropdown-link dm-link"
                      onClick={() => {
                        setIsMenuOpen(false);
                        onTkProjects();
                        setIsDropdownOpen(false);
                      }}
                    >
                      Tokenized projects
                    </div>
                  </li>
                  <li className="dropdown-item">
                    <div
                      className="dropdown-link dm-link"
                      onClick={() => {
                        setIsMenuOpen(false);
                        onFactoryProject();
                        setIsDropdownOpen(false);
                      }}
                    >
                      The Factory project
                    </div>
                  </li>
                  <li className="dropdown-item">
                    <div
                      className="dropdown-link link-last-child dm-link"
                      onClick={() => {
                        setIsMenuOpen(false);
                        onReserveCard();
                        setIsDropdownOpen(false);
                      }}
                    >
                      Reserve card
                    </div>
                  </li>
                </ul>
              )}
            </li>
          </ul>
          <div className="menu-ls" style={{  }}>
            {token ? (
              <button className="sign-up" onClick={handleLogout} style={{marginLeft: 0, border: 'none'}}>
                Logout
              </button>
            ) : (
              <>
                {" "}
                <div style={{ width: "100%" }}>
                  <Link
                    to={"/registerScreen"}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="sign-up">Sign up</div>
                  </Link>
                </div>
                <div style={{ width: "100%" }}>
                  <Link to={"/loginScreen"} style={{ textDecoration: "none" }}>
                    <div
                      className="sign-up"
                      style={{
                        marginTop: 12,
                        background: "rgba(255, 255, 255, 0.1)",
                        color: "#fff",
                      }}
                    >
                      Log in
                    </div>
                  </Link>
                </div>
              </>
            )}
            {/* <Link
              to={"https://calendly.com/olu-ekd/30min"}
              style={{ textDecoration: "none", color: "#000" }}
              className="con-btn-wrap"
              target="_blank"
            >
              <button
                className="navBar-btn con-btn"
                style={{ fontWeight: "500", color: "#000" }}
              >
                Book Consultation
              </button>
            </Link> */}
          </div>
        </div>
      )}
      <div className="navbar-links">
        <ul className="navbar-ul">
          <li className="nav-item">
            <Link className="nav-link" to="/about">
              About us
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={token ? "/blogDashboard" : "/blog"}>
              Blog
            </Link>
          </li>
          
          {/* <li className="nav-item">
            <Link className="nav-link"  to={"https://onboard.trendx.app"}>
            Onboard on Trendx
            </Link>
          </li> */}
          <li className="nav-item dropdown">
            <Link
              className="nav-link"
              to=""
              onClick={handleDropdownToggle}
              style={{
                backgroundColor: isDropdownOpen ? "#1a103a" : "transparent",
              }}
            >
              Company portfolio
              <img
                src={isDropdownOpen ? dropUp : dropDown}
                alt=""
                style={{ marginLeft: 12 }}
              />
            </Link>
            {isDropdownOpen && (
              <div className="dropdown-cont">
                <ul className="dropdown-menu">
                  <li className="dropdown-item">
                    <div
                      className="dropdown-link"
                      onClick={() => {
                        onDefi();
                        setIsDropdownOpen(false);
                      }}
                    >
                      De-Fi research
                    </div>
                  </li>
                  <li className="dropdown-item">
                    <div
                      className="dropdown-link"
                      onClick={() => {
                        onDefi();
                        setIsDropdownOpen(false);
                      }}
                    >
                      Advisory service
                    </div>
                  </li>
                  <li className="dropdown-item">
                    <div
                      className="dropdown-link"
                      onClick={() => {
                        onTxApp();
                        setIsDropdownOpen(false);
                      }}
                    >
                      Trendx app
                    </div>
                  </li>
                  <li className="dropdown-item">
                    <div
                      className="dropdown-link"
                      onClick={() => {
                        onTkProjects();
                        setIsDropdownOpen(false);
                      }}
                    >
                      Tokenized projects
                    </div>
                  </li>
                  <li className="dropdown-item">
                    <div
                      className="dropdown-link"
                      onClick={() => {
                        onFactoryProject();
                        setIsDropdownOpen(false);
                      }}
                    >
                      The Factory project
                    </div>
                  </li>
                  <li className="dropdown-item">
                    <div
                      className="dropdown-link link-last-child"
                      onClick={() => {
                        onReserveCard();
                        setIsDropdownOpen(false);
                      }}
                    >
                      Reserve card
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </li>
        </ul>
      </div>

      {token ? (
        <div className="profile-wrap onMenu-p-wrap" style={{ marginRight: 24 }}>
          <img
            // @ts-ignore
            src={userInfo.avatar === null ? defaultAvatar : userInfo.avatar}
            alt=""
            style={{ width: 32, height: 32, borderRadius: 100, objectFit: 'cover' }}
          />
          <button className="profile-drop" onClick={handleLogDropdownToggle}>
            {/* @ts-ignore */}
            {userInfo.name}
            <img src={isLogDropdownOpen ? solidDropUp : solidDropdown} alt="" />
          </button>
          {isLogDropdownOpen && (
            <div className="ppr-card" style={{ height: 40 }}>
              <button
                className="ppr-btn"
                onClick={() =>
                  // navigate("/profileScreen", { state: { fromBlog: true } })
                  handleLogout()
                }
              >
                Logout
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="login-signup">
          <Link to="/loginScreen" className="login-btn link redHat-medium">
            Log in
          </Link>
          <Link to="/registerScreen" className="link">
            <button className="navBar-btn1 redHat-medium">Sign up</button>
          </Link>
        </div>
      )}

      {/* <Link
        to={"https://calendly.com/olu-ekd/30min"}
        style={{ textDecoration: "none", color: "#000" }}
        className="cons-btn"
      >
        <button
          className="navBar-btn"
          style={{ fontWeight: "500", color: "#000" }}
        >
          Book Consultation
        </button>
      </Link> */}
      <Logout />
    </nav>
  );
};

export default Navbar;
