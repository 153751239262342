import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { CredentailsContext } from "./RequestHandlers/CredentailsContext";
import HomeScreen from "./Screens/HomeScreen";
import AboutScreen from "./Screens/AboutScreen";
import CompanyPortfolioScreen from "./Screens/CompanyPortfolioScreen";
import BlogScreen from "./Screens/BlogScreen";
import Navbar from "./components/Navbar";
import PrivacyPolicyScreen from "./Screens/PrivacyPolicyScreen";
import TermsOfUse from "./Screens/TermsOfUseScreen";
import LegalScreen from "./Screens/LegalScreen";
import ContactUsScreen from "./Screens/ContactUsScreen";
import LoginScreen from "./Screens/LoginScreen";
import RegisterScreen from "./Screens/RegisterScreen";
import BlogPostScreen from "./Screens/BlogPostScreen";
import SubscriptionScreen from "./Screens/SubscriptionScreen";
import PaymentScreen from "./Screens/PaymentScreen";
import BlogDashboard from "./Screens/BlogDashboard";
import BookmarkScreen from "./Screens/BookmarkScreen";
import AdvisoryScreen from "./Screens/AdvisoryScreen";
import ProfileScreen from "./Screens/ProfileScreen";
import DashAboutScreen from "./Screens/DashAboutScreen";
import HelpSupport from "./Screens/HelpSupport";
import FAQScreen from "./Screens/FAQScreen";
import ChangePasswordScreen from "./Screens/ChangePasswordScreen";
import UserSupscriptionScreen from "./Screens/UserSupscriptionScreen";
import ChangePlanScreen from "./Screens/ChangePlanScreen";
import PaymentInfoScreen from "./Screens/PaymentInfoScreen";
import ReactivatePlanScreen from "./Screens/ReactivatePlanScreen";
import MobileNOtificationScreen from "./Screens/MobileNotificationScreen";
import MobileSearchScreen from "./Screens/MobileSearchScreen";
import OtpScreen from "./Screens/OtpScreen";
import Cookies from "js-cookie";
import ForgotPasswordScreen from "./Screens/ForgotPasswordScreen";
import ForgotPasswordOTP from "./Screens/ForgotPasswordOTP";
import ResetPasswordScreen from "./Screens/ResetPasswordScreen";
import SearchScreen from "./Screens/SearchScreen";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripePayment from "./Screens/StripePayment";

const stripePromise = loadStripe(
  "pk_live_51PaDpS2NshWqVeHsK4TnHFN4Mn4qqY6rIccXFw4iBrA95BYm0z3UXud3k8bazPGwJb9YYXz4RyTdvTzfadu2mqEc000V5Ci1EM"
);

function App() {
  const [storedCredentails, setStoredCredentails] = useState(null);
  const credentials = Cookies.get("webCook");

  const options = {
    mode: "subscription",
    amount: 1099,
    currency: "usd",
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  useEffect(() => {
    const credentials = Cookies.get("webCook");
    if (credentials) {
      setStoredCredentails(JSON.parse(credentials));
    }
  }, []);
  const PrivateRoute = ({ children }: any) => {
    return credentials ? children : <Navigate to="/" />;
  };

  return (
    // @ts-ignore
    <CredentailsContext.Provider
      // @ts-ignore
      value={{ storedCredentails, setStoredCredentails }}
    >
      <Router>
        <Elements stripe={stripePromise}>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/loginScreen" element={<LoginScreen />} />
            <Route path="/about" element={<AboutScreen />} />
            <Route path="/blog" element={<BlogScreen />} />
            <Route
              path="/companyPortfolio"
              element={<CompanyPortfolioScreen />}
            />
            <Route path="/termsofuse" element={<TermsOfUse />} />
            <Route path="/ppScreen" element={<PrivacyPolicyScreen />} />
            <Route path="/legalScreen" element={<LegalScreen />} />
            <Route path="/contactus" element={<ContactUsScreen />} />
            <Route path="/blogPostScreen" element={<BlogPostScreen />} />
            <Route path="/registerScreen" element={<RegisterScreen />} />
            <Route path="/otpScreen" element={<OtpScreen />} />
            <Route path="/fogotPassword" element={<ForgotPasswordScreen />} />
            <Route path="/forgotPasswordOtp" element={<ForgotPasswordOTP />} />
            <Route path="/resetPassword" element={<ResetPasswordScreen />} />
            <Route
              path="/subscriptionScreen"
              element={<SubscriptionScreen />}
            />

            {/* Protected Routes */}
            <Route
              path="/blogDashboard"
              element={
                <PrivateRoute>
                  <BlogDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/subscriptionScreen1"
              element={
                <PrivateRoute>
                  <SubscriptionScreen />
                </PrivateRoute>
              }
            />

            <Route
              path="/StripePayment"
              element={
                <PrivateRoute>
                  <StripePayment />
                </PrivateRoute>
              }
            />
            <Route
              path="/paymentScreen"
              element={
                <PrivateRoute>
                  <PaymentScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/bookmarkScreen"
              element={
                <PrivateRoute>
                  <BookmarkScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/advisoryScreen"
              element={
                <PrivateRoute>
                  <AdvisoryScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/profileScreen"
              element={
                <PrivateRoute>
                  <ProfileScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashAboutScreen"
              element={
                <PrivateRoute>
                  <DashAboutScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/support"
              element={
                <PrivateRoute>
                  <HelpSupport />
                </PrivateRoute>
              }
            />
            <Route
              path="/faqScreen"
              element={
                <PrivateRoute>
                  <FAQScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/changePassword"
              element={
                <PrivateRoute>
                  <ChangePasswordScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/userSupscription"
              element={
                <PrivateRoute>
                  <UserSupscriptionScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/changePlan"
              element={
                <PrivateRoute>
                  <ChangePlanScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/paymentInfo"
              element={
                <PrivateRoute>
                  <PaymentInfoScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/reactivatePlan"
              element={
                <PrivateRoute>
                  <ReactivatePlanScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/mNotification"
              element={
                <PrivateRoute>
                  <MobileNOtificationScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/mobileSearch"
              element={
                <PrivateRoute>
                  <MobileSearchScreen />
                </PrivateRoute>
              }
            />
          </Routes>
        </Elements>
      </Router>
    </CredentailsContext.Provider>
  );
}

export default App;
