import React, { useState, useEffect, useRef } from "react";
import "../css/style.css";
import "../css/bcb.css";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import txLogo from "../assests/images/tx-logo.png";
import bookMark from "../assests/images/bookmark-plus.svg";
import bookMarkFill from "../assests/images/bookmarkFill.svg";
import share from "../assests/images/share.svg";
import postImgLarge from "../assests/images/postImgLarge.png";
import star from "../assests/images/noto_star.svg";
import polygon from "../assests/images/polygon.svg";
import onChain from "../assests/images/onChain.svg";
import FadeInSection from "../components/FadeIn";
import postImg1 from "../assests/images/postImg1.svg";
import postImg2 from "../assests/images/postImg2.svg";
import postImg3 from "../assests/images/postImg3.svg";
import paperClip from "../assests/images/papperclip.svg";
import { useMediaQuery } from "react-responsive";
import {
  GetBlogBySlug,
  BookMark,
  RemoveBookMark,
  GetRecommendation,
  GetUSerBlogBySlug,
} from "../RequestHandlers/Blogs";
import { GetCurrentPlan } from "../RequestHandlers/Subscription";
import { formatDate } from "../components/FormatDate";
import Cookies from "js-cookie";
import Toast, { handleToast } from "../components/Toast";
import { GetSubscriptionPlan } from "../RequestHandlers/User";
import Loader from "../components/Loader";
// import DOMPurify from "dompurify";

export default function BlogPostScreen() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmarkIcon, setBookMarkIcon] = useState(false);
  const [loader, setLoader] = useState(true);
  const [drop, setDrop] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [postBody, setPostBody] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [postId, setPostId] = useState(Number(0));
  const [fullPost, setFullPost] = useState([]);
  const [recommends, setRecommends] = useState([]);
  const postIdff = queryParams.get("gg");
  const [slug, setslug] = useState(location.state?.slug || postIdff);
  const [category, setCategory] = useState(location.state?.category || "");
  const [catId, setCatId] = useState(location.state?.catId || "");

  const toggleDropdown = () => {
    setDrop(!drop);
  };

  const copyToClipboard = (slug: any) => {
    const blogUrl = `${window.location.origin}/blogPostScreen/?gg=${slug}`;
    navigator.clipboard.writeText(blogUrl).then(() => {
      handleToast("Link copied successfuly");
    });
  };

  const bookmark = async () => {
    try {
      // @ts-ignore
      const itemBookmark = await BookMark(postId);
      console.log(itemBookmark);

      if (itemBookmark.email) {
        handleToast("Item bookmarked", "success");
        getUserBlogPost();
      } else {
        handleToast("Unsuccessful attempt", "error");
      }
    } catch (error) {
      console.error("Bookmark error: ", error);
    }
  };

  const getSubscriptionPlan = async () => {
    try {
      const subscription = await GetSubscriptionPlan();
      const subscriptionIsActive =
        subscription.plan.active == "1" ? true : false;
      // setIsSubscribed(subscriptionIsActive);
      console.log(subscription.plan.active, "getSubscriptionPlan");
    } catch (error) {
      console.error(error);
    }
  };

  const removeBookmark = async () => {
    try {
      // @ts-ignore
      const itemBookmark = await RemoveBookMark(postId);
      if (itemBookmark.email) {
        handleToast("Item unbookmarked", "success");
        getUserBlogPost();
      } else {
        handleToast("Unsuccessful attempt", "error");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleBookMark = async () => {
    if (!isLoggedIn) {
      navigate("/loginScreen");
    } else {
      if (isBookmarked) {
        setBookMarkIcon(false);
        removeBookmark();
      } else {
        setBookMarkIcon(true);
        bookmark();
      }
    }
  };
  const getBlogPost = async (slug: any) => {
    try {
      const blogPost = await GetBlogBySlug(slug);
      setFullPost(blogPost);
      setPostId(blogPost.id);
      // const cleanHtml = DOMPurify.sanitize(blogPost.body);
      setPostBody(blogPost.body);
     if (blogPost.id) {
      setLoader(false)
     }else{
      setLoader(false)
      handleToast(blogPost.message, "error")
     }
      
      const credentials = Cookies.get("webCook");
      if (credentials) {
        setIsBookmarked(blogPost.isBookmarked);
      } else {
        setIsBookmarked(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getUserBlogPost = async () => {
    try {
      const blogPost = await GetUSerBlogBySlug(slug);
      setFullPost(blogPost);
      setPostId(blogPost.id);
      setIsBookmarked(blogPost.isBookmarked);
      console.log(blogPost);
    } catch (error) {
      console.log(error);
    }
  };
  const getRecommendation = async (catID: any) => {
    console.log('hello world');
    
    try {
      const recommendations = await GetRecommendation(false, catId);
      setRecommends(recommendations.items);
      console.log(recommendations, 'hello');
    } catch (error) {
      console.log(error);
    }
  };
  // @ts-ignore
  const handleReadMoreClick = (slug, category, catId) => {
    // navigate("/blogPostScreen", { state: { slug, category, catId } }); // Pass the blog item as state
    setslug(slug);
    setCatId(catId);
    setCategory(category);
  };

  const getCurrentPlan = async () => {
    try {
      const currentPlan = await GetCurrentPlan();
      console.log(currentPlan);
      if (currentPlan.plan) {
        setIsSubscribed(true);
      } else {
        // setIsSubscribed(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const getRecommendation = async (catID: any) => {
      try {
        const recommendations = await GetRecommendation(false, catId);
        setRecommends(recommendations.items);
        console.log(recommendations, 'hello');
      } catch (error) {
        console.log(error);
      }
    };
    getRecommendation(catId);    
    window.scrollTo(0, 0);
    const credentials = Cookies.get("webCook");
    getSubscriptionPlan();
    if (credentials) {
      setIsLoggedIn(true);
      // getRecommendation(catId);
      // getUserBlogPost();
      getCurrentPlan();
    } else {
      setIsLoggedIn(false);
    }
    getBlogPost(slug);

    // setTimeout(() => {
    //   setLoader(false);
    // }, 3000);

    if (isLoggedIn === false) {
      // setIsSubscribed(false);
    }
  }, []);
  useEffect(() => {
    getRecommendation(catId);
    window.scrollTo(0, 0);
    const credentials = Cookies.get("webCook");
    getSubscriptionPlan();
    if (credentials) {
      setIsLoggedIn(true);
      // getRecommendation(catId);
      // getUserBlogPost();
      getCurrentPlan();
    } else {
      setIsLoggedIn(false);
    }
    getBlogPost(slug);
    setTimeout(() => {
      setLoader(false);
    }, 3000);
    if (isLoggedIn === false) {
      // setIsSubscribed(false);
    }
  }, [slug]);

  const splitHtmlContent = (htmlContent: any) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const nodes = Array.from(doc.body.childNodes);

    const totalNodes = nodes.length;
    const halfIndex = Math.ceil(totalNodes / 3);

    let firstHalf = "";
    let secondHalf = "";

    nodes.forEach((node, index) => {
      if (index < halfIndex) {
        // @ts-ignore
        firstHalf += node.outerHTML || node.textContent;
      } else {
        // @ts-ignore
        secondHalf += node.outerHTML || node.textContent;
      }
    });

    return { firstHalf, secondHalf };
  };
  const removeBrTags = (htmlContent: any) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    // Select and remove all <br> tags
    const brTags = doc.querySelectorAll("br");
    brTags.forEach((tag) => tag.remove());

    return doc.body.innerHTML;
  };
  // @ts-ignore
  const { firstHalf, secondHalf } = splitHtmlContent(fullPost.body);
  // @ts-ignore
  const truncateText = (text, maxLength) => {
    let charCount = 0;
    let truncatedText = "";

    for (let char of text) {
      if (char !== " ") {
        charCount++;
      }
      if (charCount <= maxLength) {
        truncatedText += char;
      } else {
        truncatedText += "...";
        break;
      }
    }

    return truncatedText;
  };
  const subInfo = [
    "Access subscriber-only content on TrendX",
    "Get blockchain investment recommendations",
    "Get timely and exclusive market insights",
    "Achieve your goals in the blockchain space ",
  ];

  return (
    <section className="blogPostSection">
      <Loader loader={loader}/>
      <div className="blog-nav blog-post-nav">
        <Link
          to=" "
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          className="blogArrow-back"
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3333 31.6667C13.3333 30.4301 12.1117 28.5834 10.875 27.0334C9.285 25.0334 7.385 23.2884 5.20667 21.9567C3.57334 20.9584 1.59333 20.0001 9.53674e-07 20.0001M9.53674e-07 20.0001C1.59333 20.0001 3.575 19.0417 5.20667 18.0434C7.385 16.7101 9.285 14.9651 10.875 12.9684C12.1117 11.4167 13.3333 9.56675 13.3333 8.33341M9.53674e-07 20.0001L40 20.0001"
              stroke="white"
              stroke-width="2"
            />
          </svg>
        </Link>
      </div>
      <div className="blog-post-topper"></div>
      <div className="bpb-section">
        <div className="bpb-inner-wrap">
          <FadeInSection>
            {/* @ts-ignore */}
            <p className="title-p">{fullPost.title}</p>
          </FadeInSection>
          <FadeInSection>
            <div className="bookmark-card">
              <img src={txLogo} alt="" className="tx-logo" />
              <div className="st-wrap">
                {/* @ts-ignore */}
                <p>Research Report by {fullPost.author}</p>
                <p>
                  Published in{" "}
                  <span className="text-cat">{category.toUpperCase()}</span>{" "}
                  {/* @ts-ignore */}
                  {!isMobile && `${formatDate(fullPost.created_at)}`}
                </p>
                {/* @ts-ignore */}
                {isMobile && <p>{formatDate(fullPost.created_at)}</p>}
              </div>
              <div className="bookmark-share">
                <button className="bmk-btn1">
                  <img
                    src={isBookmarked === true ? bookMarkFill : bookMark}
                    alt=""
                    onClick={() => {
                      handleBookMark();
                    }}
                  />
                </button>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <button
                    onClick={() => {
                      toggleDropdown();
                    }}
                  >
                    <img src={share} alt="" />
                  </button>
                  {drop && (
                    <div className="share-drop">
                      <img src={polygon} alt="" />
                      <div
                        className="copyLink"
                        onClick={() => {
                          setDrop(false);
                          copyToClipboard(slug);
                        }}
                      >
                        <img src={onChain} alt="" />
                        <p>Copy link</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {isMobile && (
              <div className="book-share-btns">
                <button
                  onClick={() => {
                    handleBookMark();
                  }}
                >
                  {isBookmarked === false ? (
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.7646 21.3956L8.76297 24.3683C8.47757 24.5917 8.1367 24.7331 7.77699 24.7773C7.41727 24.8216 7.05229 24.767 6.72125 24.6195C6.3902 24.472 6.1056 24.2371 5.89801 23.94C5.69041 23.6429 5.56767 23.2949 5.54297 22.9333V7.40844C5.56373 6.83638 5.697 6.27403 5.93517 5.75349C6.17334 5.23296 6.51173 4.76446 6.93102 4.37475C7.35031 3.98504 7.84227 3.68176 8.3788 3.48224C8.91534 3.28272 9.48593 3.19087 10.058 3.21194H17.9446C19.0994 3.17157 20.223 3.59061 21.0693 4.37722C21.9156 5.16383 22.4156 6.25386 22.4596 7.40844V22.9356C22.4355 23.2975 22.3131 23.6459 22.1056 23.9433C21.898 24.2407 21.6133 24.4759 21.282 24.6234C20.9507 24.7709 20.5854 24.8253 20.2255 24.7805C19.8656 24.7357 19.5247 24.5936 19.2396 24.3694L15.238 21.3968C14.8787 21.1352 14.4457 20.9943 14.0013 20.9943C13.5569 20.9943 13.1239 21.134 12.7646 21.3956Z"
                        stroke="#ffffffb3"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.9918 8.16699V15.167M10.5 11.6752H17.5"
                        stroke="#ffffffb3"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.97 6.32096V19.651C19.9477 20.1051 19.8006 20.5442 19.5448 20.92C19.2889 21.2958 18.9343 21.5938 18.52 21.781C18.1119 21.9611 17.6634 22.0301 17.22 21.981C16.7744 21.9255 16.3525 21.7491 16 21.471L12.59 18.941C12.4099 18.8144 12.1951 18.7466 11.975 18.7466C11.7549 18.7466 11.5401 18.8144 11.36 18.941L7.93 21.501C7.58195 21.772 7.16753 21.9447 6.73 22.001H6.43C6.08482 21.9995 5.74392 21.9245 5.43 21.781C5.02933 21.5973 4.68434 21.3109 4.43 20.951C4.17842 20.5798 4.03003 20.1484 4 19.701V6.34196C4.01935 5.75135 4.1551 5.17037 4.39946 4.63233C4.64382 4.0943 4.99198 3.60979 5.42398 3.20659C5.85598 2.80338 6.36332 2.48943 6.91692 2.28271C7.47051 2.07599 8.05946 1.98059 8.65 2.00196H15.38C16.5641 1.96682 17.7141 2.40105 18.5794 3.21003C19.4448 4.01901 19.9554 5.13719 20 6.32096H19.97Z"
                        fill="#D3F26A"
                      />
                    </svg>
                  )}
                  Bookmark
                </button>
                <button onClick={() => copyToClipboard(slug)}>
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.66797 14.0002V23.3335C4.66797 23.9523 4.9138 24.5458 5.35139 24.9834C5.78897 25.421 6.38246 25.6668 7.0013 25.6668H21.0013C21.6201 25.6668 22.2136 25.421 22.6512 24.9834C23.0888 24.5458 23.3346 23.9523 23.3346 23.3335V14.0002M18.668 7.00016L14.0013 2.3335M14.0013 2.3335L9.33464 7.00016M14.0013 2.3335V17.5002"
                      stroke="#ffffffb3"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Share
                </button>
              </div>
            )}
          </FadeInSection>
          {isLoggedIn ? (
            <div
              className="bcb"
              dangerouslySetInnerHTML={{
                __html: isSubscribed
                  ? // @ts-ignore
                    fullPost.body
                  : firstHalf,
              }}
            />
          ) : (
            <div
              className="bcb"
              dangerouslySetInnerHTML={{
                // @ts-ignore
                __html: fullPost.body,
              }}
            />
          )}
          {/* {isSubscribed ? (
            <>
              {blogPost.sections.map((section, index) => {
                if (section.type === "text") {
                  return (
                    <FadeInSection>
                      <p
                        className={`post-text ${
                          index === blogPost.sections.length - 1
                            ? "text-mgr"
                            : ""
                        }`}
                        key={index}
                      >
                        {section.content}
                      </p>
                    </FadeInSection>
                  );
                } else if (section.type === "image") {
                  return (
                    <FadeInSection>
                      <img
                        className={`post-img ${
                          index === blogPost.sections.length - 1
                            ? "text-mgr"
                            : ""
                        }`}
                        src={section.src}
                      />
                      ;
                    </FadeInSection>
                  );
                }
                return null;
              })}
            </>
          ) : (
            <>
              {blogPost.sections.slice(0, 5).map((section, index) => {
                if (section.type === "text") {
                  return (
                    <FadeInSection>
                      <p className="post-text" key={index}>
                        {section.content}
                      </p>
                    </FadeInSection>
                  );
                } else if (section.type === "image") {
                  return (
                    <FadeInSection>
                      <img className="post-img" src={section.src} />;
                    </FadeInSection>
                  );
                }
                return null;
              })}
            </>
          )} */}
          {!isSubscribed && (
            <div className="subscription-section">
              <div className="blend"></div>
              <div className="sub-modal">
                <FadeInSection>
                  <p className="sub-text-head">
                    Read this research report and other premium reports on
                    TrendX
                  </p>
                </FadeInSection>
                <FadeInSection>
                  <p className="sub-text-p">
                    This report is made available to TrendX subscribers only.
                    Subscribe to unlock this report and other perks.
                  </p>
                </FadeInSection>
                {subInfo.map((_, index) => {
                  return (
                    <FadeInSection>
                      <div className="sub-list-wrap">
                        <img src={star} alt="" />
                        <p className="sub-list">{_}</p>
                      </div>
                    </FadeInSection>
                  );
                })}
                <FadeInSection>
                  <Link
                    to={
                      "/subscriptionScreen"
                      // isLoggedIn ? "/userSupscription" : "/subscriptionScreen"
                    }
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <div className="consult-btn btn-sec sub-btn">
                      <p style={{ color: "black" }} className="sub-btn-p">
                        Subscribe now
                      </p>
                      <svg
                        width="56"
                        height="56"
                        viewBox="0 0 56 56"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="56" height="56" rx="28" fill="black" />
                        <path
                          d="M17.3334 28H20.6667M38.6667 28L30.6667 20M38.6667 28L30.6667 36M38.6667 28H24.6667"
                          stroke="white"
                          stroke-width="2.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </Link>
                </FadeInSection>
              </div>
            </div>
          )}
          {isSubscribed && (
            <>
              {recommends.length === 0 ? (
                <></>
              ) : (
                <>
                  <p className="rcdr">Recommended reports</p>
                  <div className="main-blog-section rcdr-top" style={{marginBottom: 118}}>
                    <div
                      className="main-blog-section blog-section1"
                      style={{ paddingTop: 0 }}
                    >
                      <>
                        {recommends.map((item, index) => {
                          // @ts-ignore
                          const parser = new DOMParser();
                          const doc = parser.parseFromString(
                            // @ts-ignore
                            item.body,
                            "text/html"
                          );
                          let firstParagraph = doc.querySelector("p");
                          const firstImag = doc.querySelector("img");

                          // Check if the first paragraph is empty or contains only whitespace
                          if (
                            !firstParagraph ||
                            // @ts-ignore
                            firstParagraph.textContent.trim() === ""
                          ) {
                            const paragraphs = doc.querySelectorAll("p");
                            if (paragraphs.length > 1) {
                              firstParagraph = paragraphs[1];
                            }
                          }

                          return (
                            <FadeInSection>
                              <div
                                className="blog-content-card db-content-card recommend"
                                onClick={(e) => {
                                  e.preventDefault();
                                  // @ts-ignore
                                  handleReadMoreClick(
                                    // @ts-ignore
                                    item.slug,
                                    // @ts-ignore
                                    item.category.name,
                                    // @ts-ignore
                                    item.category.id
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <div className="blog-card-header db-header">
                                  <div className="bch-wrap bch-wrap1">
                                    <img src={paperClip} alt="" />
                                    <p className="bch-p bch-p1">
                                      {/* @ts-ignore */}
                                      {item.category.name}
                                    </p>
                                  </div>
                                  <p className="db-date">
                                    {/* @ts-ignore */}
                                    {formatDate(item.created_at)}
                                  </p>
                                </div>
                                <div className="line-seperator db-seperator"></div>
                                <p className="blog-title blog-title1">
                                  {/* @ts-ignore */}
                                  {item.title}
                                </p>
                                <p className="blog-post-body bp-body1">
                                  {firstParagraph
                                    ? truncateText(
                                        firstParagraph.textContent,
                                        120
                                      )
                                    : ""}
                                  <span>
                                    <Link
                                      to={""}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleReadMoreClick(
                                          // @ts-ignore
                                          item.slug,
                                          // @ts-ignore
                                          item.category.name,
                                          // @ts-ignore
                                          item.category.id
                                        );
                                      }}
                                      className="readMore"
                                    >
                                      Read More
                                    </Link>
                                  </span>
                                </p>

                                <img
                                  // @ts-ignore
                                  src={firstImag ? firstImag.src : ""}
                                  className="b-postImg"
                                  alt=""
                                />
                              </div>
                            </FadeInSection>
                          );
                        })}
                      </>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <Toast />
    </section>
  );
}
